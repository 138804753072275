import { Component, OnInit } from '@angular/core';
import { IdiomaService } from '../../services/idioma.service';

@Component({
  selector: 'app-inicio',
  templateUrl: './inicio.component.html',
  styleUrls: ['./inicio.component.css']
})
export class InicioComponent implements OnInit {


  constructor( public idiomaSrvc: IdiomaService ) { }

  ngOnInit() {
  }

  cambiarIdioma(idioma: string) {
    // if ( this.idiomaSrvc.idioma === 'espanol') {
    //   this.idiomaSrvc.cambiarIdioma('portugues');
    // } else {
    //   if ( this.idiomaSrvc.idioma === 'portugues') {
    //     this.idiomaSrvc.cambiarIdioma('ingles');
    //   } else {
    //     this.idiomaSrvc.cambiarIdioma('espanol');
    //   }
    // }
    if ( idioma !== this.idiomaSrvc.idioma ) {
      this.idiomaSrvc.cambiarIdioma(idioma);
    }
  }

  cargarFrase( frase: number) {
    return this.idiomaSrvc.cargarFrase( frase );
  }
}
