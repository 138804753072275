import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class IdiomaService {
  bandera = '';
  idioma = '';
  idiomaxDef = 'espanol';
  listado: { frase: string, espanol: string, ingles: string, portugues: string } [] = [
    { frase: 'texto_001', espanol: 'Inicio', ingles: 'Home', portugues: 'Iniciação' },
    { frase: 'texto_002', espanol: 'Turismo Maranata', ingles: 'Maranata Tourism', portugues: 'Turismo Maranata' },
    { frase: 'texto_003', espanol: 'Español', ingles: 'Spanish', portugues: 'Espanhol' },
    { frase: 'texto_004', espanol: 'Inglés', ingles: 'English', portugues: 'Inglês' },
    { frase: 'texto_005', espanol: 'Portugués', ingles: 'Portuguese', portugues: 'Português' },
    { frase: 'texto_006', espanol: 'Quienes Somos', ingles: 'About Us', portugues: 'Quem somos' },
    { frase: 'texto_007', espanol: 'Destinos', ingles: 'Destinations', portugues: 'Destinos' },
    { frase: 'texto_008', espanol: 'Flota', ingles: 'Fleet', portugues: 'Frota' },
    { frase: 'texto_009', espanol: 'Contacto', ingles: 'Contact Us', portugues: 'Contato' },
    { frase: 'texto_010', espanol: 'Centros de Ski', ingles: 'Ski Centers', portugues: 'Centros de esqui' },
    { frase: 'texto_011', espanol: 'Ruta del Vino', ingles: 'Wine Route', portugues: 'Rota do Vinho' },
    // tslint:disable-next-line:max-line-length
    { frase: 'texto_012', espanol: 'Nuestra empresa Turismo Maranata, nace con la principal característica de la necesidad de satisfacer la alta demanda de clientes nacionales y de todo el mundo, siendo Chile hoy conocido como un "Destino turístico". Tenemos como objetivo lograr un servicio de alto estándar de calidad en todas las áreas. Todos nuestros servicios se adaptan a las necesidades o requerimientos del cliente, contamos con profesionales especializados, logrando una excelente capacidad de gestión, liderazgo y emprendimiento; características fundamentales que nos han llevado a tomar una reconocida posición en el mercado.', ingles: 'Our company Maranata Tourism, was born with the main characteristic of the need to meet the high demand of national clients and from all over the world, being Chile today known as a "Tourist Destination". We aim to achieve a high quality service in all areas. All our services are adapted to the needs or requirements of the client, we have specialized professionals, achieving an excellent management, leadership and entrepreneurship capacity; fundamental characteristics that have led us to take a recognized position in the market.', portugues: 'Nossa empresa Turismo Maranata, nasceu com a principal característica da necessidade de atender a alta demanda de clientes nacionais e de todo o mundo, sendo o Chile hoje conhecido como "Destino Turístico". Nosso objetivo é alcançar um serviço de alta qualidade em todas as áreas. Todos os nossos serviços estão adaptados às necessidades ou exigências do cliente, contamos com profissionais especializados, alcançando uma excelente capacidade de gestão, liderança e empreendedorismo; características fundamentais que nos levaram a assumir uma posição reconhecida no mercado.' },
    // tslint:disable-next-line:max-line-length
    { frase: 'texto_013', espanol: 'Turismo Maranata ha experimentado un crecimiento constante, gracias al respaldo y confianza que nos han brindado nuestros clientes, y a la experiencia de sus ejecutivos, quienes tienen más de 10 años en el rubro turístico, logrando y otorgando un servicio de primera categoría con un estilo personal y profesionalizado. Los invitamos a conocernos y disfrutar de nuestro país junto a Turismo Maranata.', ingles: 'Maranata Tourism has experienced a constant growth, thanks to the support and trust that our clients have given us, and the experience of its executives, who have more than 10 years in the tourist sector, achieving and providing a first-class service with a personal style and professionalized. We invite you to meet us and enjoy our country with Maranata Tourism.', portugues: 'O Turismo Maranata tem experimentado um crescimento constante, graças ao apoio e confiança que os nossos clientes nos proporcionaram e à experiência dos seus executivos, que têm mais de 10 anos no setor turístico, alcançando e prestando um serviço de primeira classe com um estilo pessoal. e profissionalizado. Nós convidamos você a nos conhecer e desfrutar do nosso país com Turismo Maranata.' },
    { frase: 'texto_014', espanol: 'Misión', ingles: 'Mission', portugues: 'Missão' },
    // tslint:disable-next-line:max-line-length
    { frase: 'texto_015', espanol: 'Organizar viajes, estando comprometidos con la calidad de los servicios y atención hacia nuestros clientes.', ingles: 'Organize trips, being committed to the quality of services and attention to our customers.', portugues: 'Organizar viagens, comprometendo-se com a qualidade dos serviços e atenção aos nossos clientes.' },
    { frase: 'texto_016', espanol: 'Visión', ingles: 'Vision', portugues: 'Visão' },
    // tslint:disable-next-line:max-line-length
    { frase: 'texto_017', espanol: 'Desarrollar en el mercado una empresa líder en turismo receptivo y emisivo.', ingles: 'Develop in the market a leading company in receptive and outgoing tourism.', portugues: 'Desenvolver no mercado uma empresa líder em turismo receptivo e de saída.' },
    // tslint:disable-next-line:max-line-length
    { frase: 'texto_018', espanol: 'Le invitamos a conocer los mejores destinos que hemos escogido de nuestro sin igual Chile y nos permita organizar un tour para usted.', ingles: 'We invite you to know the best destinations we have chosen from our unparalleled Chile and allow us to organize a tour for you.', portugues: 'Convidamos você a conhecer os melhores destinos que escolhemos de nosso inigualável Chile e nos permitir organizar um tour para você.' },
    { frase: 'texto_019', espanol: 'Todo', ingles: 'All', portugues: 'Tudo' },
    { frase: 'texto_020', espanol: 'V Región', ingles: 'V Region', portugues: 'V Região' },
    { frase: 'texto_021', espanol: 'Viña', ingles: 'Vineyard', portugues: 'Vinhedo' },
    { frase: 'texto_022', espanol: 'Valle de Colchagua', ingles: 'Colchagua Valley', portugues: 'Vale de Colchagua' },
    { frase: 'texto_023', espanol: 'Palacio de La Moneda', ingles: 'La Moneda Palace', portugues: 'Palácio de La Moneda' },
    { frase: 'texto_024', espanol: 'Termas de Chillán', ingles: 'Chillán Hot Springs', portugues: 'Fontes termais de Chillán' },
    // tslint:disable-next-line:max-line-length
    { frase: 'texto_025', espanol: 'Centro de Ski El Colorado se ha transformado en el centro ideal para la práctica del deporte blanco por parte de la familia y la juventud durante cada temporada invernal.', ingles: 'Ski Center El Colorado has become the ideal center for the practice of white sport by family and youth during each winter season.', portugues: 'O Centro de Esqui El Colorado tornou-se o centro ideal para a prática do esporte branco pela família e juventude durante cada temporada de inverno.' },
    // tslint:disable-next-line:max-line-length
    { frase: 'texto_026', espanol: 'Bisquertt es una viña familiar fundada por Osvaldo Bisquertt Revecco y su señora Soledad Urrutia Parot, quienes en 1965 decidieron plantar sus primeros viñedos en el Fundo Rinconada de Peralillo en el Valle de Colchagua. ', ingles: 'Bisquertt is a family vineyard founded by Osvaldo Bisquertt Revecco and his wife Soledad Urrutia Parot, who in 1965 decided to plant their first vineyards in the Rinconada de Peralillo Fundo in the Colchagua Valley.', portugues: 'Bisquertt é uma vinícola familiar fundada por Osvaldo Bisquertt Revecco e sua esposa Soledad Urrutia Parot, que em 1965 decidiram plantar seus primeiros vinhedos na Rinconada de Peralillo Fundo, no Vale de Colchagua.' },
    // tslint:disable-next-line:max-line-length
    { frase: 'texto_027', espanol: 'El Cajón del Maipo es un cañón andino ubicado en la zona suroriental de la Región Metropolitana, Chile. Corresponde a la alta cuenca del río Maipo (sobre 900 msnm), donde es encajonado por cerros, farellones y macizos.', ingles: 'El Cajón del Maipo is an Andean canyon located in the southeast of the Metropolitan Region, Chile. It corresponds to the high basin of the Maipo River (about 900 meters above sea level), where it is encased by hills, farellones and massifs.', portugues: 'El Cajón del Maipo é um cânion andino localizado no sudeste da Região Metropolitana, Chile. Corresponde à bacia alta do rio Maipo (cerca de 900 metros acima do nível do mar), onde é envolto por morros, farellones e maciços.' },
    // tslint:disable-next-line:max-line-length
    { frase: 'texto_028', espanol: 'Viña Casa Silva es una empresa 100% familiar, que busca traspasar al mundo y a sus futuras generaciones, el orgullo de sus raíces y el respeto por la naturaleza y su gente. ', ingles: 'Casa Silva vineyard is a 100% family business, which seeks to transfer to the world and its future generations, the pride of its roots and respect for nature and its people.', portugues: 'Vinhedo Casa Silva é uma empresa familiar 100%, que busca transferir para o mundo e suas futuras gerações, o orgulho de suas raízes e o respeito pela natureza e seu povo.' },
    // tslint:disable-next-line:max-line-length
    { frase: 'texto_029', espanol: 'Viña Concha y Toro es la principal productora y exportadora de vinos de Latinoamérica y una de las 10 mayores compañías de vino en el mundo.', ingles: 'Concha y Toro vineyard is the main wine producer and exporter in Latin America and one of the 10 largest wine companies in the world.', portugues: 'Vinhedo Concha y Toro é a principal produtora e exportadora de vinhos da América Latina e uma das 10 maiores empresas de vinhos do mundo.' },
    // tslint:disable-next-line:max-line-length
    { frase: 'texto_030', espanol: 'Viña Emiliana se caracteriza por producir solo vinos orgánicos y biodinámicos, de manera tal que ayuda a preservar el equilibrio natural de la vida, el ser humano y el medio ambiente.', ingles: 'Emiliana vineyard is characterized by producing only organic and biodynamic wines, in such a way that it helps preserve the natural balance of life, the human being and the environment.', portugues: 'Vinhedo Emiliana caracteriza-se por produzir apenas vinhos orgânicos e biodinâmicos, de forma a preservar o equilíbrio natural da vida, do ser humano e do meio ambiente.' },
    // tslint:disable-next-line:max-line-length
    { frase: 'texto_031', espanol: 'La propiedad está situada en Pirque, area central del país, una de las más prestigiosas y reconocidas zonas del Valle del Maipo para la plantación de viñedos.', ingles: 'The property is located in Pirque, central area of the country, one of the most prestigious and recognized areas of the Maipo Valley for planting vineyards.', portugues: 'A propriedade está localizada em Pirque, área central do país, uma das áreas mais prestigiadas e reconhecidas do Vale do Maipo para o plantio de vinhedos.' },
    // tslint:disable-next-line:max-line-length
    { frase: 'texto_032', espanol: 'Producir vinos de calidad que respondan a las necesidades de sus clientes, y entregar una experiencia única alrededor del vino, con una oferta turística y gastronómica de excelencia.', ingles: 'Produce quality wines that meet the needs of its customers, and deliver a unique experience around the wine, with a tourist and gastronomic offer of excellence.', portugues: 'Produza vinhos de qualidade que atendam às necessidades de seus clientes e proporcionem uma experiência única em torno do vinho, com uma oferta gastronômica e turística de excelência.' },
    // tslint:disable-next-line:max-line-length
    { frase: 'texto_033', espanol: 'El Palacio de La Moneda, es la sede del presidente de la República de Chile, también alberga la Secretaría General de la Presidencia, y la Secretaría General de Gobierno y se ubica en la comuna de Santiago. ', ingles: 'La Moneda Palace, is the headquarters of the President of the Republic of Chile, also houses the General Secretariat of the Presidency, and the General Secretariat of Government and is located in the commune of Santiago.', portugues: 'O Palácio de La Moneda, é a sede do Presidente da República do Chile, também abriga a Secretaria Geral da Presidência, e da Secretaria Geral de Governo e está localizado na comuna de Santiago.' },
    // tslint:disable-next-line:max-line-length
    { frase: 'texto_034', espanol: 'Emplazado en uno de los sectores más hermosos de la Cordillera y tan sólo a 50 kms de Santiago, siguiendo el Camino a Farellones se encuentra el Centro de Ski La Parva.', ingles: 'Located in one of the most beautiful sectors of the Cordillera and only 50 kms from Santiago, following the Camino a Farellones is the La Parva Ski Center.', portugues: 'Localizado em um dos mais belos setores da Cordilheira e apenas a 50 km de Santiago, seguindo o Camino a Farellones é o Centro de Esqui La Parva.' },
    // tslint:disable-next-line:max-line-length
    { frase: 'texto_035', espanol: 'La historia de Morandé comienza hace 20 años atrás, en 1996, con el propósito de elaborar los mejores vinos, siempre innovadores y de primera calidad, a partir de viñedos de los valles vitivinícolas más interesantes del país.', ingles: 'The history of Morandé begins 20 years ago, in 1996, with the purpose of producing the best wines, always innovative and of first quality, from vineyards of the most interesting wine valleys in the country.', portugues: 'A história de Morandé começa há 20 anos, em 1996, com o objetivo de produzir os melhores vinhos, sempre inovadores e de primeira qualidade, dos vinhedos dos vales de vinho mais interessantes do país.' },
    // tslint:disable-next-line:max-line-length
    { frase: 'texto_036', espanol: 'Pueblo turístico donde su principal atractivo es la calle Roberto Bravo donde se vende artesanía en greda (o arcilla) y se ofrecen los platos típicos de la cocina chilena, como son el pastel de choclo y la empanada.', ingles: 'Tourist town where its main attraction is Roberto Bravo Street where handicrafts are sold in clay (or clay) and the typical dishes of Chilean cuisine are offered, such as corn cake and empanada.', portugues: 'Pueblo turístico é um dos principais atractivos da rua de Roberto Bravo, que é o seu mais vendido em greda (o arco-íris) e dos seus pratos típicos da cozinha chilena, como o pastel de choclo e a empanada.' },
    // tslint:disable-next-line:max-line-length
    { frase: 'texto_037', espanol: 'Portillo es el más antiguo centro de esquí de América del Sur y, hasta hoy, el único de la región que ha sido sede de un mundial de esquí.  Desde 1949, este centro de esquí ha recibido más de 3.000.000 de pasajeros.', ingles: 'Portillo is the oldest ski center in South America and, until today, the only one in the region that has hosted a ski World Cup. Since 1949, this ski center has received more than 3,000,000 passengers.', portugues: 'Portillo é o centro de esqui mais antigo da América do Sul e, até hoje, o único da região que sediou uma Copa do Mundo de esqui. Desde 1949, este centro de esqui recebeu mais de 3.000.000 de passageiros.' },
    // tslint:disable-next-line:max-line-length
    { frase: 'texto_038', espanol: 'El centro termal tiene nueve piscinas de azufre y hierro, con aguas a una temperatura promedio de 60 °C. Las aguas son sulfaltadas y contienen hierro, azufre, manganeso, magnesio y potasio, entre otros minerales.', ingles: 'The thermal center has nine sulfur and iron pools, with waters at an average temperature of 60 ° C. The waters are sulphided and contain iron, sulfur, manganese, magnesium and potassium, among other minerals.', portugues: 'O centro termal tem nove piscinas de enxofre e ferro, com águas a uma temperatura média de 60 ° C. As águas são sulfetadas e contêm ferro, enxofre, manganês, magnésio e potássio, entre outros minerais.' },
    // tslint:disable-next-line:max-line-length
    { frase: 'texto_039', espanol: 'En los más de 135 años que Viña Santa Rita ha trabajado en la elaboración de sus vinos, le han otorgado una vasta experiencia que sin duda se traspasa en la calidad de sus productos.', ingles: 'In the more than 135 years that Viña Santa Rita has worked in the elaboration of its wines, they have given it a vast experience that undoubtedly goes beyond the quality of its products.', portugues: 'Nos mais de 135 anos que a Viña Santa Rita trabalhou na elaboração dos seus vinhos, proporcionaram-lhe uma vasta experiência que, indubitavelmente, vai além da qualidade dos seus produtos.' },
    // tslint:disable-next-line:max-line-length
    { frase: 'texto_040', espanol: 'En Valle Nevado Ski Resort podrás disfrutar de la montaña durante todo el año. Esquía en las pistas más altas de Chile y con la mejor nieve de Sudamérica. Clases de Ski y Snowboard en plena Cordillera de Los Andes.', ingles: 'In Valle Nevado Ski Resort you can enjoy the mountain throughout the year. Skiing on the highest slopes in Chile and with the best snow in South America. Ski and Snowboard lessons in the Andes Mountains.', portugues: 'Na Estância de Esqui Valle Nevado, pode desfrutar da montanha ao longo do ano. Esquiar nas encostas mais altas do Chile e com a melhor neve da América do Sul. Aulas de esqui e snowboard nas montanhas dos Andes.' },
    // tslint:disable-next-line:max-line-length
    { frase: 'texto_041', espanol: 'Viña del Mar, también es conocida como la "Ciudad Jardín" se ubica en la costa central de la V Región de Chile, a 120 km. de la capital, Santiago.', ingles: 'Viña del Mar, is also known as the "Garden City" is located on the central coast of the V Region of Chile, 120 km. from the capital, Santiago.', portugues: 'Viña del Mar, também é conhecida como a "Cidade Jardim" está localizada na costa central da Região V do Chile, a 120 km. da capital, Santiago.' },
    // tslint:disable-next-line:max-line-length
    { frase: 'texto_042', espanol: 'Nuestra empresa cuenta con una moderna flota de autos, vans, minibuses y buses full equipo, todos inscritos en el ministerio de transportes, con seguro de pasajero y conducidos por conductores profesionales.', ingles: 'Our company has a modern fleet of cars, vans, minibuses and full equipment buses, all registered in the Ministry of Transportation, with passenger insurance and driven by professional drivers.', portugues: 'Nossa empresa possui uma moderna frota de carros, vans, microônibus e ônibus de equipamentos completos, todos registrados no Ministério dos Transportes, com seguro de passageiros e dirigidos por motoristas profissionais.' },
    { frase: 'texto_043', espanol: 'nombre', ingles: 'name', portugues: 'nome' },
    { frase: 'texto_044', espanol: 'correo', ingles: 'e-mail', portugues: 'email' },
    { frase: 'texto_045', espanol: 'mensaje', ingles: 'message', portugues: 'mensagem' },
    { frase: 'texto_046', espanol: 'Enviar', ingles: 'Send', portugues: 'Enviar' },
    { frase: 'texto_047', espanol: 'Las últimas novedades', ingles: 'The latest news', portugues: 'As últimas notícias' },
    { frase: 'texto_048', espanol: 'Promociones y novedades', ingles: 'Promotions and news', portugues: 'Promoções e novidades' },
    { frase: 'texto_049', espanol: 'Autos Ejecutivos', ingles: 'Executive cars', portugues: 'Carros Executivos' },
    { frase: 'texto_050', espanol: 'Minibuses - Buses', ingles: 'Minibuses - Buses', portugues: 'Microônibus - Ônibus' },
    { frase: 'texto_051', espanol: 'teléfono', ingles: 'phone', portugues: 'telefone' },
    // tslint:disable-next-line:max-line-length
    { frase: 'texto_052', espanol: 'Valparaíso es una ciudad portuaria de la costa de Chile. Es conocida por sus empinados funiculares y las coloridas casas sobre los cerros.', ingles: 'Valparaíso is a port city on the coast of Chile. It is known for its steep funicular and the colorful houses on the hills.', portugues: 'Valparaíso é uma cidade portuária na costa do Chile. É conhecido por seu funicular e as casas coloridas nas colinas.' },
    // tslint:disable-next-line:max-line-length
    { frase: 'texto_053', espanol: 'Isla Negra es una localidad ubicada en la zona sur de la comuna de El Quisco, en la Región de Valparaíso, la localidad, a pesar de no ser una isla propiamente tal, fue bautizada con este nombre por el poeta chileno Pablo Neruda, al ver una roca negra en el mar, cerca de su casa ubicada frente al océano. ', ingles: 'Isla Negra is a town located in the southern part of the commune of El Quisco, in the Valparaíso Region, the town, despite not being an island itself, was named after the Chilean poet Pablo Neruda, seeing a black rock in the sea, near his house located in front of the ocean.', portugues: 'Isla Negra é uma cidade localizada na parte sul da comuna de El Quisco, na região de Valparaíso, a cidade, apesar de não ser uma ilha em si, foi nomeado após o poeta chileno Pablo Neruda, vendo uma rocha negra no mar, perto de sua casa localizada em frente ao oceano.' },
    { frase: 'texto_054', espanol: '', ingles: '', portugues: '' },
    { frase: 'texto_055', espanol: '', ingles: '', portugues: '' },
    { frase: 'texto_056', espanol: '', ingles: '', portugues: '' },
    { frase: 'texto_057', espanol: '', ingles: '', portugues: '' },
    { frase: 'texto_058', espanol: '', ingles: '', portugues: '' },
    { frase: 'texto_059', espanol: '', ingles: '', portugues: '' },
  ];
  texto: { frase: string, idioma: string } [];

  constructor() {
      this.cargarIdioma();
  }
  cargarIdioma() {
    console.log('NAVIGATOR.LANGUAGE', navigator);
    if ( localStorage.getItem('idioma')) {
      this.idioma = localStorage.getItem( 'idioma' );
    } else {
      if (navigator.language.substr( 0, 2) === 'es') {
        this.idioma = this.idiomaxDef;
      }
      if (navigator.language.substr( 0, 2) === 'en') {
        this.idioma = 'ingles';
      }
      if (navigator.language.substr( 0, 2) === 'pt') {
        this.idioma = 'portugues';
      }
    }
    console.log('Cargando Idioma: ', this.idioma);
    this.guardarIdioma();
  }

  guardarIdioma() {
    localStorage.setItem( 'idioma', this.idioma );
    if ( this.idioma === 'espanol') {
      this.bandera = '/assets/img/bandera-brasil.png';
    }
    if ( this.idioma === 'ingles') {
      this.bandera = '/assets/img/bandera-chile3.png';
    }
    if ( this.idioma === 'portugues') {
      this.bandera = '/assets/img/bandera-inglaterra.png';
    }
    // console.log('bandera:', this.bandera);
    console.log('idioma:', this.idioma );

  }

  cargarFrase( numero: number ) {
   if (this.idioma === 'espanol') {
    // console.log('cargando frase:', numero);
    return this.listado[numero - 1].espanol;
   }
   if (this.idioma === 'ingles') {
    return this.listado[numero - 1].ingles;
   }
   if (this.idioma === 'portugues') {
    return this.listado[numero - 1].portugues;
   }
  }

  cambiarIdioma( idioma: string ) {
    console.log('Cambiando idioma a: ', idioma);
    this.idioma = idioma;
    this.guardarIdioma();
  }
}
